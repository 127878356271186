import "@/assets/fontawesome-pro-5.15.1/css/all.min.css";
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import "vuetify/dist/vuetify.min.css";

import fr from "vuetify/es5/locale/fr";
import en from "vuetify/es5/locale/en";

Vue.use(Vuetify);

export default new Vuetify({
  lang:
  {
    locales: {fr, en},
    current: "fr",
  },

  theme:
  {
    themes:
    {
      light:
      {
        primary: process.env.VUE_APP_THEME_PRIMARY,
        secondary: process.env.VUE_APP_THEME_SECONDARY,
        accent: process.env.VUE_APP_THEME_ACCENT,
        error: process.env.VUE_APP_THEME_ERROR,
        info: process.env.VUE_APP_THEME_INFO,
        success: process.env.VUE_APP_THEME_SUCCESS,
        warning: process.env.VUE_APP_THEME_WARNING
      },

      dark:
      {
        primary: process.env.VUE_APP_THEME_PRIMARY,
        secondary: process.env.VUE_APP_THEME_SECONDARY,
        accent: process.env.VUE_APP_THEME_ACCENT,
        error: process.env.VUE_APP_THEME_ERROR,
        info: process.env.VUE_APP_THEME_INFO,
        success: process.env.VUE_APP_THEME_SUCCESS,
        warning: process.env.VUE_APP_THEME_WARNING
      }
    }
  },

  icons:
  {
    iconfont: 'fa'
  }
});
