import Vue from 'vue'
import Vuex from 'vuex'
import pageHead from '@/store/pageHead';
import toast from '@/store/toast';
import alert from '@/store/alert';
import modes from "@/store/modes";
import itinerary from "@/store/itinerary";

import router from '@/plugins/router';

Vue.use(Vuex)

export default new Vuex.Store({

  state: {

    showLeftPanel: true,
    showPointDetails: false,

    zoom: 15,

    coords: {lng: 2.352222, lat: 48.856613},

    search: null,

    only: null,

    isMobile: false,
    showAdDialog: false,
    showLandingDialog: false
  },

  getters: {

    coords: state => state.coords,
    zoom: state => state.zoom,
    search: state => state.search,
    only: state => state.only,
    showLeftPanel: state => state.showLeftPanel,
    showPointDetails: state => state.showPointDetails,
    isMobile: state => state.isMobile,
    showAdDialog: state => state.showAdDialog,
    showLandingDialog: state => state.showLandingDialog

  },

  mutations: {

    setCoordinates(state, {lng, lat}) {
      Vue.set(state, 'coords', {lng: Number(lng).toFixed(6), lat: Number(lat).toFixed(6)});
    },

    setZoom(state, zoom) {
      Vue.set(state, 'zoom', Number(Number(zoom).toFixed(2)))
    },

    setSearch(state, search) {
      Vue.set(state, 'search', search);
    },

    setOnly(state, only) {
      Vue.set(state, 'only', only);
    },

    setShowLeftPanel(state, showLeftPanel) {
      Vue.set(state, "showLeftPanel", showLeftPanel);
    },

    setShowPointDetails(state, showPointDetails) {
      Vue.set(state, "showPointDetails", showPointDetails);
    },

    setIsMobile(state, isMobile) {
      Vue.set(state, "isMobile", isMobile);
    },

    setShowAdDialog(state, showAdDialog) {
      Vue.set(state, "showAdDialog", showAdDialog);
    },

    setShowLandingDialog(state, showLandingDialog) {
      Vue.set(state, "showLandingDialog", showLandingDialog);
    }

  },

  actions: {

    logout({dispatch, rootGetters, commit}) {
      this._vm.$session.destroy();
      commit("alert/reset");
      commit("pageHead/reset");
      commit("toast/reset");
    }

  },

  modules: {
    pageHead,
    toast,
    alert,
    modes,
    itinerary
  }
})
