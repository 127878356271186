import Vue from 'vue'
import App from './App.vue'
import router from './plugins/router'
import store from './store'
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import {HTTP} from "./plugins/http-common";
import VueSession from 'vue-session';
import vueMoment from 'vue-moment';
import moment from 'moment';
require('moment/locale/en-gb');
require('moment/locale/fr');
//import Vue2MapboxGL from 'vue2mapbox-gl';
import VueMapbox from '@studiometa/vue-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

import GSignInButton from 'vue-google-signin-button'
import FBSignInButton from 'vue-facebook-signin-button'

import Ads from 'vue-google-adsense'
import './registerServiceWorker'

Vue.config.productionTip = false

import Vue2TouchEvents from 'vue2-touch-events';

Vue.use(Vue2TouchEvents, {namespace: 'toucha', swipeTolerance: 10});
Vue.use(VueSession, {persist: true});
Vue.use(vueMoment, {moment});
//Vue.use(Vue2MapboxGL);
Vue.use(VueMapbox);
Vue.use(GSignInButton);
Vue.use(FBSignInButton);

Vue.use(require('vue-script2'));
Vue.use(Ads.Adsense);

Vue.filter("truncate", function(text, length = 40, clamp = "...") {
  return `${text.slice(0, length)} ${(length < text.length ? clamp : '')}`;
});

Vue.config.productionTip = false;
Vue.prototype.$http = HTTP;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  VueSession,
  render: h => h(App)
}).$mount('#app')
