export const metro = ['==', ['get', 'physical_mode'], 'SUBWAY'];
export const tramway = ['==', ['get', 'physical_mode'], 'TRAMWAY'];
export const train = ['==', ['get', 'physical_mode'], 'TRAIN'];
export const shuttle = ['==', ['get', 'physical_mode'], 'SHUTTLE'];
export const funicular = ['==', ['get', 'physical_mode'], 'FUNICULAR'];
export const rer = ['==', ['get', 'physical_mode'], 'RER'];
export var bus = ['==', ['get', 'physical_mode'], 'BUS'];
export var boat = ['==', ['get', 'physical_mode'], 'BOAT'];
export var car = ['==', ['get', 'physical_mode'], 'CAR'];
export var motorscooter = ['==', ['get', 'physical_mode'], 'MOTORSCOOTER'];
export var bike = ['==', ['get', 'physical_mode'], 'BIKE'];
export var bikestation = ['==', ['get', 'physical_mode'], 'BIKESTATION'];
export var scooter = ['==', ['get', 'physical_mode'], 'SCOOTER'];

/**
 * A intégrer dans le component Source de react-map-gl
 * clusterProperties={clusterProperties}
 *
 * ATTENTION, cette variable sert si vous voulez créer des donuts quand ça clusterise
 * Sinon dans la dynamique actuelle cela count chaque type de transport et fausse le setState
 * car ne prend pas en compte le re-init (je ne sais pas pourquoi d'ailleurs)
 */
export const clusterProperties = {
    'metro': ['+', ['case', metro, 1, 0]],
    'tramway': ['+', ['case', tramway, 1, 0]],
    'train': ['+', ['case', train, 1, 0]],
    'shuttle': ['+', ['case', shuttle, 1, 0]],
    'funicular': ['+', ['case', funicular, 1, 0]],
    'rer': ['+', ['case', rer, 1, 0]],
    'bus': ['+', ['case', bus, 1, 0]],
    'boat': ['+', ['case', boat, 1, 0]],
    'car': ['+', ['case', car, 1, 0]],
    'motorscooter': ['+', ['case', motorscooter, 1, 0]],
    'bike': ['+', ['case', bike, 1, 0]],
    'bikestation': ['+', ['case', bikestation, 1, 0]],
    'scooter': ['+', ['case', scooter, 1, 0]]
}

/**
 * CLUSTER ZOOM <12
 */
export const clusterLayer = {
    id: 'clusters',
    source: "cluster-physical-mode",
    type: 'circle',
    filter: ['has', 'point_count'],
    maxzoom: 12,
    paint: {
        'circle-color': ['step', ['get', 'point_count'], '#03b3ff', 100, '#f2e71d', 500, '#ff578c'],
        'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40]
    }
};

export const clusterCountLayer = {
    id: 'cluster-count',
    source: "cluster-physical-mode",
    type: 'symbol',
    filter: ['has', 'point_count'],
    maxzoom: 12,
    layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12
    }
};

export const markerLayer = {
    id: 'marker-cluster',
    source: "marker-source",
    type: 'circle',
    //maxzoom: 10,
    filter: ['all', ['!', ['has', 'point_count']]],
    paint: {
        'circle-stroke-color': [
            'match',
            ['get', 'physical_mode'],
            'BOAT',
            '#54CEFF',
            'BUS',
            '#FF8765',
            'METRO',
            '#5318FF',
            'SHUTTLE',
            '#CE05F4',
            'FUNICULAR',
            '#F205F4',
            'RER',
            '#274290',
            'TRAMWAY',
            '#8089FF',
            'TRAIN',
            '#0F084B',
            'MOTORCYCLE',
            '#DA2F73',
            'ELECTRIC_SCOOTER',
            '#F5DC00',
            'BIKE',
            '#7AC856',
            'BIKE_STATION',
            '#46AC1F',
            'CAR',
            '#ED768E',
            'WC',
            '#fd6c9e',
            "DEFIBRILLATOR",
            "#6495ED",
            "E_CHARGER",
            "#87CEEB",
            '#000'
        ],
        'circle-radius': 6,
        'circle-stroke-width': 9,
        'circle-color': '#fff'
    }

};

/**
 * PINS
 */
export const unclusteredPointLayer = {
    id: 'unclustered-point',
    source: "cluster-physical-mode",
    type: 'circle',
    filter: [
        'all',
        ['!', ['has', 'point_count']]
    ],
    paint: {
        'circle-color': [
            'match',
            ['get', 'physical_mode'],
            'BOAT',
            '#54CEFF',
            'BUS',
            '#FF8765',
            'METRO',
            '#5318FF',
            'SHUTTLE',
            '#CE05F4',
            'FUNICULAR',
            '#F205F4',
            'RER',
            '#274290',
            'TRAMWAY',
            '#8089FF',
            'TRAIN',
            '#0F084B',
            'MOTORCYCLE',
            '#DA2F73',
            'ELECTRIC_SCOOTER',
            '#F5DC00',
            'BIKE',
            '#7AC856',
            'BIKE_STATION',
            '#46AC1F',
            'CAR',
            '#ED768E',
            'WC',
            '#fd6c9e',
            "DEFIBRILLATOR",
            "#6495ED",
            "E_CHARGER",
            "#87CEEB",
            '#000'
        ],
        'circle-radius': 7,
        'circle-stroke-width': 1,
        'circle-stroke-color': '#fff'
    }
};

export const itineraryLineLayer = {
    id: 'itinerary-line',
    source: "itinerary-source",
    type: 'line',
    'layout': {
        'line-join': 'round',
        'line-cap': 'round'
    },
    'paint': {
        'line-color': '#4D59FF',
        'line-dasharray': [2, 1.5],
        "line-width": 5
    }
};

export const publicTransportsLayer = {
    id: 'idf-network-layer',
    source: "idf-network",
    type: 'line',
    'layout': {
        'line-join': 'round',
        'line-cap': 'round'
    },
    'paint': {
        'line-color': ['get', 'color'],
        'line-width': 4
    }
}