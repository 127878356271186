var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-main',[_c('div',{staticStyle:{"width":"100vw","height":"100vh","background-color":"white","border":"none !important"}},[_c('mapbox-map',{attrs:{"access-token":_vm.mapboxToken,"map-style":_vm.mapStyle,"zoom":_vm.zoom,"center":[_vm.coords.lng, _vm.coords.lat],"attributionControl":false,"fadeDuration":100},on:{"mb-created":_vm.onMapCreated,"mb-dragend":_vm.onDragend,"mb-zoomend":_vm.onZoomEnded},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('v-overlay',{attrs:{"opacity":0.7}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-img',{attrs:{"src":require("@/assets/pictures/favicon.png"),"id":"roote-img-loader","width":"200px"}})],1)],1)]},proxy:true}])},[(!_vm.isMobile)?_c('mapbox-navigation-control',{attrs:{"showCompass":false,"position":"bottom-right"}}):_vm._e(),(!_vm.isMobile || (_vm.isMobile && !_vm.toggleMainPanel))?_c('mapbox-geolocate-control',{attrs:{"trackUserLocation":true,"positionOptions":{enableHighAccuracy: true},"position":"bottom-right"},on:{"mb-trackuserlocationend":_vm.onGeolocationEnded}}):_vm._e(),_c('mapbox-source',{attrs:{"id":"cluster-physical-mode","options":{
           type: 'geojson',
            clusterRadius: 50,
            data: {
              type: 'FeatureCollection',
              features: this.physicalModesPoints
          }}}}),_c('mapbox-layer',{attrs:{"id":_vm.unclusteredPointLayer.id,"options":_vm.unclusteredPointLayer}}),_c('mapbox-source',{attrs:{"id":"itinerary-source","options":{
           type: 'geojson',
            data: {
              type: 'Feature',
              properties: {
                length: this.itineraryLines.length
              },
              geometry: {
                type: 'LineString',
                coordinates: this.itineraryLines
              }
            }
          }}}),(_vm.$route.name === 'ItineraryDetails')?_c('mapbox-layer',{attrs:{"id":_vm.itineraryLineLayer.id,"options":_vm.itineraryLineLayer}}):_vm._e(),_c('mapbox-source',{attrs:{"id":"idf-network","options":{
           type: 'geojson',
            data: '/idf-network.geojson'
          }}}),_c('mapbox-layer',{attrs:{"id":_vm.publicTransportsLayer.id,"options":_vm.publicTransportsLayer}}),(_vm.currentPoint)?_c('mapbox-source',{attrs:{"id":"marker-source","options":{
           type: 'geojson',
            cluster: true,
            clusterMaxZoom: 12,
            clusterRadius: 50,
            data: {
              type: 'FeatureCollection',
              features: [_vm.currentPoint]
          }}}}):_vm._e(),(['PointDetailsPublicTransports', 'PointDetailsMicroMobility', 'PointDetailsOther'].includes(_vm.$route.name))?_c('mapbox-layer',{attrs:{"id":_vm.markerLayer.id,"options":_vm.markerLayer}}):_vm._e(),(_vm.currentMarker)?_c('mapbox-marker',{attrs:{"color":'#4D59FF',"lng-lat":[_vm.currentMarker.lng, _vm.currentMarker.lat]}}):_vm._e(),(_vm.popupData)?_c('mapbox-popup',{attrs:{"autoPlan":false,"keepInView":true,"closeButton":false,"lng-lat":[_vm.popupData.lng, _vm.popupData.lat]}},[_c('h3',{staticStyle:{"font-family":"Catamaran !important"}},[_vm._v(_vm._s(_vm.popupData.label))])]):_vm._e()],1),(!_vm.isMobile || (_vm.isMobile && !_vm.toggleMainPanel))?_c('ItineraryBtn'):_vm._e(),(_vm.isMobile)?_c('LeftPanelMobile',{staticClass:"hidden-lg-and-up"}):_c('LeftPanel',{staticClass:"hidden-md-and-down"})],1),_c('Toast'),_c('PageHeadHandler')],1),_c('AdsDialog'),_c('LandingDialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }