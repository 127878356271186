import modes from "@/constants/modes.json"

export default  {
  data() {
    return {
      ALL_MODES: modes,
      configQuery: this.$session.exists() ? {
        headers: {
          Authorization: `Bearer ${this.$session.get("jwt")}`
        }
      } : {}
    }
  },

  methods: {
    searchQuery() {

      if (this.$store.getters["search"] !== this.$route.query.q) {
        return new Promise((resolve, reject) => {

          console.log(`Searching ${this.$route.query.q}`);
          this.$http.get(`/map/search?q=${this.$route.query.q}`, this.configQuery)
            .then((res) => {

              if (res.data.success) {

                // Set detected mode(s)
                if (!!res.data.mode) {

                  let newModes = [];
                  this.ALL_MODES.forEach((mode) => {
                    if (mode.matching && mode.matching.includes(res.data.mode))
                      newModes.push(mode.name);
                  });

                  if (newModes.length > 0) {
                    this.$store.commit("modes/disableAll");
                    this.$store.commit("modes/enableModes", newModes);
                  }
                }

                if (!!res.data.address) {
                  const lat = res.data.coordinates.lat;
                  const lng = res.data.coordinates.lng;
                  const zoom = 15;
                  //this.$router.replace({name: "Home", query: {q: this.$route.query.q, coords: `${lng},${lat}`, zoom}});
                  this.$store.commit("setCoordinates", {lng, lat});
                  this.$store.commit("setZoom", zoom);
                  this.$store.commit("modes/setCurrentPoint", {lng, lat});
                  //this.handleViewportChange(viewport);
                  return resolve();

                } else if (!!res.data.mode) {
                  this.getTransportAround(this.state.current_location.lat, this.state.current_location.lng, this.state.physical_mode)
                  return resolve();
                }
              } else {
                this.$store.commit("toast/showError",
                  this.$t('view.search.errors.NO_RESULT'));
                return resolve();
              }
            })
            .catch((err) => {
              console.log(err);
            this.$store.commit("toast/showError", this.$t('common.errors.500'));
            return resolve();
          })
        });
      }
    },

    displayPointsAfterMoving(prevCoords, nextCoords, zoom){
      let distanceAfterMoving = this.distanceBetweenCoordinates(prevCoords.lat, prevCoords.lng, nextCoords.lat, nextCoords.lng);
      //console.log(distanceAfterMoving);
      //console.log((distanceAfterMoving/zoom))
      //console.log(this.$store.getters['modes/getPoints'].length);
      return ((distanceAfterMoving >= 1.00 && zoom >= 12) ||
        (zoom >= 13 && this.$store.getters['modes/getPoints'].length === 0)) ||
        (zoom >= 16 && (distanceAfterMoving > 0.50));
    },

    distanceBetweenCoordinates(lat1, lon1, lat2, lon2) {

      var rad = (x) => {return x*Math.PI/180;}

      var R     = 6378.137;                     //La ratio de la Terre en km (WGS84)
      var dLat  = rad( lat2 - lat1 );
      var dLong = rad( lon2 - lon1 );

      var a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.cos(rad(lat1)) * Math.cos(rad(lat2)) * Math.sin(dLong/2) * Math.sin(dLong/2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
      var d = R * c;

      return d.toFixed(2)
    }
  }
}