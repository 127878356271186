import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n);

const messages = {
  fr: {

    account: require('../translations/account.fr.json'),
    view: require('../translations/view.fr.json'),
    common: require('../translations/common.fr.json')

  },
  en: {

    account: require('../translations/account.en.json'),
    view: require('../translations/view.en.json'),
    common: require('../translations/common.en.json')
  }
};

export default new VueI18n(
{
  locale: "fr",
  fallbackLocale: "fr",
  messages: messages,
  silentTranslationWarn: true
});