<template>
  <v-app>
    <v-main>

      <div style="width: 100vw; height: 100vh;background-color: white;border: none !important;">

        <mapbox-map
          :access-token="mapboxToken"
          :map-style="mapStyle"
          :zoom="zoom"
          :center="[coords.lng, coords.lat]"
          :attributionControl="false"
          :fadeDuration="100"
          @mb-created="onMapCreated"
          @mb-dragend="onDragend"
          @mb-zoomend="onZoomEnded"
        >
          <template v-slot:loader>
            <v-overlay :opacity="0.7">
              <v-row justify="center">
                <v-img src="@/assets/pictures/favicon.png" id="roote-img-loader" width="200px"/>
              </v-row>
            </v-overlay>
          </template>

          <mapbox-navigation-control
            v-if="!isMobile"
            :showCompass="false"
            position="bottom-right"
          />

          <mapbox-geolocate-control
            v-if="!isMobile || (isMobile && !toggleMainPanel)"
            :trackUserLocation="true"
            :positionOptions="{enableHighAccuracy: true}"
            position="bottom-right"
            @mb-trackuserlocationend="onGeolocationEnded"
          />

          <mapbox-source
            id="cluster-physical-mode"
            :options="{
             type: 'geojson',
              clusterRadius: 50,
              data: {
                type: 'FeatureCollection',
                features: this.physicalModesPoints
            }}" />


          <mapbox-layer
            :id="unclusteredPointLayer.id"
            :options="unclusteredPointLayer" />

          <!-- Itinerary source -->
          <mapbox-source
            id="itinerary-source"
            :options="{
             type: 'geojson',
              data: {
                type: 'Feature',
                properties: {
                  length: this.itineraryLines.length
                },
                geometry: {
                  type: 'LineString',
                  coordinates: this.itineraryLines
                }
              }
            }" />

          <!-- Itinerary layer -->
          <mapbox-layer
            v-if="$route.name === 'ItineraryDetails'"
            :id="itineraryLineLayer.id"
            :options="itineraryLineLayer" />

          <!-- IdF network source -->
          <mapbox-source
            id="idf-network"
            :options="{
             type: 'geojson',
              data: '/idf-network.geojson'
            }" />

          <!-- IdF network layer -->
          <mapbox-layer
            :id=" publicTransportsLayer.id"
            :options="publicTransportsLayer" />

          <!-- Marker source -->
          <mapbox-source
              v-if="currentPoint"
            id="marker-source"
            :options="{
             type: 'geojson',
              cluster: true,
              clusterMaxZoom: 12,
              clusterRadius: 50,
              data: {
                type: 'FeatureCollection',
                features: [currentPoint]
            }}" />

          <!-- Marker layer -->
          <mapbox-layer
            v-if="['PointDetailsPublicTransports', 'PointDetailsMicroMobility', 'PointDetailsOther'].includes($route.name)"
            :id="markerLayer.id"
            :options="markerLayer" />

          <!-- Current position marker v-if="['PointDetailsPublicTransports', 'PointDetailsMicroMobility', 'PointDetailsOther'].includes($route.name)" -->
          <mapbox-marker
            v-if="currentMarker"
            :color="'#4D59FF'"
            :lng-lat="[currentMarker.lng, currentMarker.lat]">
          </mapbox-marker>

          <!-- Pop up -->
          <mapbox-popup
            v-if="popupData"
            :autoPlan="false"
            :keepInView="true"
            :closeButton="false"
            :lng-lat="[popupData.lng, popupData.lat]">
            <h3 style="font-family: Catamaran !important;">{{popupData.label}}</h3>
          </mapbox-popup>

        </mapbox-map>
        <ItineraryBtn
          v-if="!isMobile || (isMobile && !toggleMainPanel)"/>

        <LeftPanelMobile v-if="isMobile" class="hidden-lg-and-up" />
        <LeftPanel v-else class="hidden-md-and-down" />

      </div>

      <Toast />

      <PageHeadHandler />

    </v-main>

    <AdsDialog />

    <LandingDialog />


  </v-app>
</template>

<script>
import searchMixin from "@/mixins/search"
import {unclusteredPointLayer, markerLayer, itineraryLineLayer, publicTransportsLayer} from "@/constants/clustersProperties";


export default {

  name: "Map",

  components: {
    AdsDialog: () => import("@/components/Common/Dialogs/AdsDialog"),
    ItineraryBtn: () => import('@/components/Common/Controls/ItineraryBtn'),
    LeftPanel: () => import('@/components/LeftPanelRoot'),
    LeftPanelMobile: () => import("@/components/LeftPanelRootMobile"),
    MainPanel: () => import("@/views/MainPanel"),
    SearchControl: () => import("@/components/Common/Topbar"),
    Account: () => import("@/components/Common/Controls/AccountTopBar"),
    PageHeadHandler: () => import( "@/components/Common/PageHeadHandler"),
    Toast: () => import("@/components/Common/Toast"),
    LandingDialog: () => import("@/components/Common/Dialogs/LandingDialog")
  },

  mixins: [searchMixin],

  data() {
    return {
      mapboxToken: process.env.VUE_APP_MAPBOX_TOKEN,
      //mapStyle: 'mapbox://styles/mapbox/streets-v11',
      mapStyle: "mapbox://styles/madafaka/ckppf9ipv09b217nzouq5lxs0",
      mapbox: null,
      map: null,
      watchCoords: () => null,
      watchPoints: () => [],
      watchItineraryFrom: () => null,
      watchItineraryTo: () => null,
      isLoading: false,
      isWatching: false,
      popupData: null,
      isMouseOver: false,

      unclusteredPointLayer,
      markerLayer,
      itineraryLineLayer,
      publicTransportsLayer,
      control: null,

      oldViewport: null,
      public_transports: ['TRAIN', 'RER', 'METRO', 'TRAMWAY', 'SHUTTLE', 'BUS', 'BOAT'],
      micro_mobility: ['MOTORCYCLE', 'ELECTRIC_SCOOTER', 'BIKE', 'BIKE_STATION', 'CAR'],
    }
  },

  mounted() {
    this.$store.commit("setIsMobile", ['xs', 'sm', 'md'].includes(this.$vuetify.breakpoint.name));
    //this.$store.dispatch("modes/updateSettings", {jwt: this.$session.get("jwt"), userID: this.$session.get('id')});
    this.$store.dispatch("modes/getSettings", {jwt: this.$session.get("jwt"), userID: this.$session.get('id')});
  },

  methods: {

    initWatch() {

      this.watchPoints = this.$store.watch(
        (state, getters) => getters.points,
        (newValue, oldValue) => {
          this.physicalModesPoints = newValue;
        });
    },

    onMapCreated(mapboxInstance) {

      this.map = mapboxInstance;

      this.map.on("click", "unclustered-point", (e) => {
        if (e.features.length > 0 /*&& this.$route.params.id !== e.features[0].properties.id*/) {
          console.log(e.features[0]);
          const physicalMode =  e.features[0].properties.physical_mode;
          this.currentPoint = e.features[0];
          this.toggleMainPanel = true;

          if (this.public_transports.includes(physicalMode)) {

            if (this.$route.name === 'PointDetailsPublicTransports') {
              if (this.$route.params.id !== e.features[0].properties.id) {
                this.$router.replace({
                  params: {id: e.features[0].properties.id},
                  query: {
                    coords: this.$route.query.coords,
                    zoom: this.$route.query.zoom,
                    mode: this.currentPoint.properties.physical_mode
                  }
                });
              }
              else if (this.$route.params.id === e.features[0].properties.id && this.$route.query.mode.toUpperCase() !== this.currentPoint.properties.physical_mode.toUpperCase()) {
                this.$router.replace({query: {...this.$route.query, mode: this.currentPoint.properties.physical_mode}});
              }
            }
            else if (this.$route.name !== 'PointDetailsPublicTransports')
              this.$router.push({name: "PointDetailsPublicTransports", params: {id: e.features[0].properties.id}, query: {coords: this.$route.query.coords, zoom: this.$route.query.zoom, mode: this.currentPoint.properties.physical_mode}});
          }

          else if (this.micro_mobility.includes(physicalMode)) {
            if (this.$route.name === 'PointDetailsMicroMobility') {
              if (this.$route.query.mode === this.currentPoint.properties.physical_mode)
                this.$router.replace({query: {...this.$route.query, zoom: this.$route.query.zoom, mode: this.currentPoint.properties.physical_mode, slug: this.currentPoint.properties.slug, id: this.currentPoint.properties.id}});
              else
                this.$router.replace({query: {...this.$route.query, zoom: this.$route.query.zoom, mode: this.currentPoint.properties.physical_mode, slug: this.currentPoint.properties.slug}});
            }
            else
              this.$router.push({name: 'PointDetailsMicroMobility', query: {...this.$route.query, zoom: this.$route.query.zoom, mode: this.currentPoint.properties.physical_mode, slug: this.currentPoint.properties.slug, id: this.currentPoint.properties.id}});
          }
          else if (this.$route.name !== "PointDetailsOther" || this.$route.params.id !== e.features[0].properties.id)
            this.$router.push({name: "PointDetailsOther", params: {id: e.features[0].properties.id}, query: {coords: this.$route.query.coords, zoom: this.$route.query.zoom}}).catch((err) =>{
              console.log(err);
            });

        }
      });

      this.map.on("mouseover", "unclustered-point", (e) => {

        if (e && e.features.length > 0) {
          this.map.getCanvas().style.cursor = 'pointer';
          this.isMouseOver = true;
          this.popupData = {
            lat: e.features[0].geometry.coordinates[1],
            lng: e.features[0].geometry.coordinates[0],
            point: e.point,
            label: e.features[0].properties.name
          }
        }
      });

      this.map.on("mouseout", "unclustered-point", (e) => {

        this.map.getCanvas().style.cursor = '';
        this.popupData = null;
        this.isMouseOver = false;
      });

      this.map.once("idle", () => {
        console.log("IDLE ONCE");
        this.setCoords();
      })

      this.initWatch();
    },

    onDragend() {
      console.log("DRAGEND");
      this.setCoords();
    },

    getTransportsAround () {
      this.$store.dispatch("modes/getTransportsAround", {
        modes: this.$store.getters["modes/getEnabled"],
        keepOldData: false,
        jwt: this.$session.get('jwt'),
        messages: {
          ERROR_MODE_NOT_LOADED: this.$t('viewer.errors.MODES_NOT_LOADED')
        }
      });
    },

    onZoomEnded() {
      this.setCoords();
    },

    setCoords() {
      const {lng, lat} = this.map.getCenter();
      const zoom_ = this.map.getZoom();
      const lng2 = Number(lng).toFixed(6);
      const lat2 = Number(lat).toFixed(6);

      if (this.coords.lng !== lng2 || this.coords.lat !== lat2) {
        this.coords = {lng, lat};
        this.zoom = zoom_;
      }
    },

    getMyPosition() {
      return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            console.log(this.map);
            return resolve({lng: position.coords.longitude, lat: position.coords.latitude});
          }, (error) => {
            this.$store.commit("toast/showIndication", this.$t('view.geolocation.error'));
            return resolve(null);
          });
        }
        else {
          this.$store.commit("toast/showIndication", this.$t('view.geolocation.error'));
          return resolve(null);
        }
      })
    },

    onGeolocationEnded() {

      this.currentMarker = this.coords;
      if (this.isMobile)
        this.toggleMainPanel = false;
    },

    triggerGetTransportsAround(oldCoords, newCoords) {
      if (this.displayPointsAfterMoving(oldCoords, newCoords, parseInt(this.$route.query.zoom))) {
        this.getTransportsAround();
        this.oldViewport = newCoords;
      }
    }

  },

  computed: {

    physicalModesPoints: {
      get() {
        return this.$store.getters["modes/getPoints"];
      },

      set(val) {
        this.$store.commit("modes/setPoints", val);
      }
    },

    currentPoint: {
      get() {
        return this.$store.getters["modes/getCurrentPoint"];
      },

      set(val) {
        this.$store.commit("modes/setCurrentPoint", val);
      }
    },

    currentMarker: {
      get() {
        return this.$store.getters["modes/getCurrentMarker"];
      },

      set(val) {
        this.$store.commit("modes/setCurrentMarker", val);
      }
    },

    coords: {

      get() {
        return this.$store.getters['coords']
      },

      set(val) {
        this.$store.commit("setCoordinates", val);
      }
    },

    zoom: {
      get() {
        return this.$store.getters['zoom']
      },
      set(val) {
        this.$store.commit("setZoom", val);
      }
    },

    toggleMainPanel: {
      get() {
        return this.$store.getters['showLeftPanel'];
      },
      set(val) {
        this.$store.commit("setShowLeftPanel", val);
      }
    },

    itineraryLines: {
      get() {
        return this.$store.getters["itinerary/itineraryGeoJSONLines"]
      }
    },

    isMobile() {
      return this.$store.getters['isMobile']
    }
  },

  beforeDestroy() {
    this.watchPoints();
  },

  watch: {
    '$route.query.q'(newValue, oldValue) {
      if (newValue !== oldValue && !!newValue) {
        this.searchQuery();
      }
    },

    '$route.query.coords'(newValue, oldValue) {

      if (newValue !== oldValue && !!newValue) {
        const a = newValue.split(',');
        this.coords = {lat: a[0], lng: a[1]};
        if (typeof oldValue == 'undefined' && !this.$store.getters['modes/getIsLoading'])
          this.triggerGetTransportsAround({lng: 0, lat: 0}, this.coords);
      }
    },

    '$route.query.zoom'(newValue, oldValue) {
      if (newValue !== oldValue && !!newValue) {
        this.zoom = Number(newValue);
      }
    },

    '$route.query.only'(newValue, oldValue) {
      if (newValue !== oldValue && !!newValue) {
        if (newValue === "all") {
          this.$store.commit("modes/enableAll");
          return;
        }

        this.$store.commit("modes/onlyMode", newValue);
      }
    },

    '$route.query.landing'(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue === "true") {
          this.$store.commit("setShowLandingDialog", true);
        }
        else
          this.$store.commit("setShowLandingDialog", false);
      }
    },

    '$vuetify.breakpoint.name'(newValue, oldValue) {
      if (newValue !== oldValue){
        this.$store.commit("setIsMobile", ['xs', 'sm', 'md'].includes(newValue));
      }
    },

    async coords(newValue, oldValue) {

        const coordinates = this.$route.query.coords?.split(",");
        console.log(coordinates);
        // If query and new coords are different
        if ((coordinates && coordinates.length > 0 && (coordinates[0] !== newValue.lat || coordinates[1] !== newValue.lng))) {
          console.log(`Updating from (${coordinates[0]}, ${coordinates[1]}) to (${newValue.lat}, ${newValue.lng})`);

          this.$router.replace({
            query: {
              ...this.$route.query,
              coords: `${newValue.lat},${newValue.lng}`,
              zoom: this.zoom
            }
          });

          this.triggerGetTransportsAround(this.oldViewport ?? {lat: coordinates[0], lng: coordinates[1]}, newValue);

          if (this.currentMarker == null)
            this.currentMarker = {lng: coordinates[1], lat: coordinates[0]};
        }
        // if query has no coords
        else if (typeof coordinates === "undefined" || coordinates?.length === 0) {
          if (typeof this.$route.query.q === "undefined") {
            const myPos = await this.getMyPosition();
            if (myPos) {

              this.$router.replace({
                query: {
                  ...this.$route.query,
                  coords: `${myPos.lat},${myPos.lng}`,
                  zoom: this.zoom
                }
              });

              this.currentMarker = myPos;
            }
            else {
              this.$router.replace({
                query: {
                  ...this.$route.query,
                  coords: `48.856613,2.352222`,
                  zoom: this.zoom
                }
              });
              this.getTransportsAround();
              this.currentMarker = myPos;
            }
          }
          /*

          this.getTransportsAround();

           */
        }
      //}
      console.log(this.$route.query);
      this.isWatching = true;
    }
  },

}
</script>

<style type="text/css">

/* MOBILE */
@media (max-width: 1264px) {
  .mapboxgl-ctrl-bottom-right {
    margin-bottom: 60px !important;
  }

  .mapboxgl-ctrl.mapboxgl-ctrl-group:first-child {
    position: fixed !important;
    border-radius: 50% !important;
    margin-right: 10px !important;
    border: none !important;
    bottom: 110px !important;
    right: 50px !important;
  }

  .mapboxgl-ctrl-group:not(:empty) {
    box-shadow: none !important;
  }

  .mapboxgl-ctrl button:not(:disabled):hover {
    /*background-color: transparent !important;*/
  }

  .mapboxgl-ctrl-group > button.mapboxgl-ctrl-geolocate {


    background-color: white !important;
    position: fixed !important;
    width: 50px !important;
    height: 50px !important;
    background: url("assets/icons/controls/cursor.png") no-repeat 55% 55%;
    background-size: 55%;
    border-radius: 25px !important;
  }

  .mapboxgl-ctrl-group > button.mapboxgl-ctrl-geolocate.mapboxgl-ctrl-geolocate-active {
    position: fixed !important;
    width: 50px !important;
    height: 50px !important;
    background: url("assets/icons/controls/cursor_fill.png") no-repeat 55% 55%;
    background-size: 55%;
    border-radius: 20px !important;
  }


  .mapboxgl-ctrl-group > button.mapboxgl-ctrl-geolocate > .mapboxgl-ctrl-icon,
  .mapboxgl-ctrl-group > button.mapboxgl-ctrl-zoom-in > .mapboxgl-ctrl-icon,
  .mapboxgl-ctrl-group > button.mapboxgl-ctrl-zoom-out > .mapboxgl-ctrl-icon {
    background-image: none !important;
  }
}

@media (min-width: 1264px) {

  .mapboxgl-ctrl-bottom-right {
    margin-bottom: 10px !important;
  }

  .mapboxgl-ctrl.mapboxgl-ctrl-group:first-child {
    border-radius: 50% !important;
    margin-right: 20px !important;
    border: none !important;
  }

  .mapboxgl-ctrl.mapboxgl-ctrl-group:nth-child(2) {
    border-radius: 25% !important;
    margin-right: 22px !important;
    border: none !important;
  }

  .mapboxgl-ctrl-group:not(:empty) {
    box-shadow: none !important;
  }

  .mapboxgl-ctrl button:not(:disabled):hover {
    /*background-color: transparent !important;*/
  }

  .mapboxgl-ctrl-group > button.mapboxgl-ctrl-geolocate {

    width: 50px !important;
    height: 50px !important;
    background: url("assets/icons/controls/cursor.png") no-repeat 55% 55%;
    background-size: 55%;
    border-radius: 20px !important;
  }

  .mapboxgl-ctrl-group > button.mapboxgl-ctrl-geolocate.mapboxgl-ctrl-geolocate-active {
    width: 50px !important;
    height: 50px !important;
    background: url("assets/icons/controls/cursor_fill.png") no-repeat 55% 55%;
    background-size: 55%;
    border-radius: 20px !important;
  }


  .mapboxgl-ctrl-group > button.mapboxgl-ctrl-geolocate > .mapboxgl-ctrl-icon,
  .mapboxgl-ctrl-group > button.mapboxgl-ctrl-zoom-in > .mapboxgl-ctrl-icon,
  .mapboxgl-ctrl-group > button.mapboxgl-ctrl-zoom-out > .mapboxgl-ctrl-icon {
    background-image: none !important;
  }

  .mapboxgl-ctrl-group > button.mapboxgl-ctrl-zoom-in {

    width: 45px !important;
    height: 40px !important;;
    background: url("assets/icons/controls/add.png") no-repeat 50% 50%;
    background-size: 40%;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
  }

  .mapboxgl-ctrl-group > button.mapboxgl-ctrl-zoom-out {

    width: 45px !important;
    height: 40px !important;
    background: url("assets/icons/controls/minus.png") no-repeat 50% 100%;
    background-size: 45%;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

.mapboxgl-map {
  width: 100%;
  height: 100%;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  /*display: none;*/
}


html {
  overflow-x: hidden !important;
  overflow-y: hidden !important
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden !important;
  font-family: Catamaran !important;
}

.grecaptcha-badge {
  display: none !important;
  visibility: hidden !important;
}

.v-btn {
  text-transform: none !important;
}


#search-bar .v-input__control > .v-input__append-inner, #search-bar .v-text-field .v-input__prepend-inner, #search-bar .v-text-field .v-input__append-inner {
  padding-top: -5px !important;
  margin-top: -5px !important;
}

.v-application {
  font-family: "Catamaran" !important;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

#roote-img-loader {
  animation: img-rotation 2s infinite linear;
}

@keyframes img-rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>