import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store/index";

Vue.use(VueRouter)

const routes = [
  {
    path: "/error/:status",
    name: "error",
    meta: {isPublic: true},
    component: () => import("../views/Error")
  },

  {
    path: '/',
    name: "Home",
    component: () => import("@/views/MainPanel"),
    meta: {isPublic: true}
  },

  {
        path: "/auth/login",
        name: "Login",
        component: () => import("@/views/Login"),
        meta: {isPublic: true}
      },
      {
        path: "/auth/registration",
        name: "Registration",
        component: () => import("@/views/Registration"),
        meta: { isPublic: true }
      },
      {
        path: "/point-details/:id",
        name: "PointDetailsOther",
        component: () => import("@/views/PointDetails"),
        meta: {isPublic: true}
      },
      {
        path: "/public-transports/:id",
        name: "PointDetailsPublicTransports",
        component: () => import("@/views/PointDetailsPublicTransports"),
        meta: {isPublic: true}
      },
      {
        path: "/micro-mobility",
        name: "PointDetailsMicroMobility",
        component: () => import("@/views/PointDetailsMicroMobility"),
        meta: {isPublic: true}
      },
      {
        path: "/itinerary",
        name: "Itinerary",
        component: () => import("@/views/Itinerary"),
        meta: {isPublic: true}
      },
      {
        path: "/itinerary-details",
        name: "ItineraryDetails",
        component: () => import("@/views/ItineraryDetails"),
        meta: {isPublic: true}
      },
      {
        path: "/profile",
        name: "Profile",
        component: () => import("@/views/Profile")
      },
      {
        path: "/my-addresses",
        name: "MyAddresses",
        component: () => import("@/views/MyFavorites")
      },
      {
        path: "/my-history",
        name: "MyHistory",
        component: () => import("@/views/MyHistory")
      },
      {
        path: "/legal",
        name: "Legal",
        component: () => import("@/views/Legal")
      },
      {
        path: "/privacy-policy",
        name: "PrivacyPolicy",
        component: () => import("@/views/PrivacyPolicy")
      },
  {
    path: "/auth/confirm-registration",
    name: "ConfirmRegistration",
    component: () => import("../views/ConfirmRegistration"),
    meta: {isPublic: true}
  },

  {
    path: "/auth/reset-password",
    name: "ResetPassword",
    component: () => import("../views/ResetPassword"),
    meta: { isPublic: true }
  },

  {
    path: "*",
    name: "not_found",
    meta: {isPublic: true},
    redirect: "/error/404"
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});



router.beforeEach((to, from, next) => {

  store.commit("alert/hideAlert");

  if (to.name !== from.name)
    store.commit("setShowLeftPanel", true);

  if (!to.matched.some(record => record.meta.isPublic) && !router.app.$session.exists()) {
    next({name: "Login"});
  }
  else
    next((vm) => {
      vm.from = from;
    });
});

export default router
