function initialState()
{
  return {
    title: null,
    description: null
  }
}

const pageHead =
{
  namespaced: true,

  state: initialState(),

  getters:
  {
    getTitle: state =>
    {
      return state.title;
    },
    getDescription: state =>
    {
      return state.description;
    }
  },

  mutations:
  {
    setTitle: (state, payload) =>
    {
      //console.log(payload);
      state.title = (payload) ? `${payload} - Roote` : "Roote";
    },

    setDescription: (state, payload) =>
    {
      state.description = payload;
    },

    reset (state)
    {
      const s = initialState();
      Object.keys(s).forEach(key =>
      {
        state[key] = s[key]
      })
    }
  }
};

export default pageHead;