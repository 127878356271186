import Vue from "vue";
import qs from "qs";
import {HTTP} from "@/plugins/http-common";
import router from "@/plugins/router";
import { i18n } from '@/plugins/i18n.js';
import ALL_MODES from "@/constants/modes.json";


function initialState() {
  return {

    display: {
      TRAIN: true,
      METRO: true,
      RER: true,
      TRAMWAY: true,
      SHUTTLE: true,
      BUS: true,
      BOAT: true,
      CAR: true,
      MOTORCYCLE: true,
      ELECTRIC_SCOOTER: true,
      BIKE: true,
      BIKE_STATION: true,
      DEFIBRILLATOR: false,
      E_CHARGER: false,
      WC: false
    },

    points: [],
    isLoading: false,
    currentPoint: null,
    currentMarker: null
  }
}

const publicTransportation = ["TRAIN", "METRO", "RER", "TRAMWAY", "SHUTTLE", "BUS", "BOAT"];
const alternativeTransportation = ["CAR", "MOTORCYCLE", "ELECTRIC_SCOOTER", "BIKE", "BIKE_STATION"];
const pointOfInterest = ["DEFIBRILLATOR", "E_CHARGER", "WC"];

const modes = {

  namespaced: true,

  state: initialState(),

  getters: {

    isEnabled: state => mode => {
      return state.display[mode];
    },

    getEnabled: state => {
      let ret = [];
      for (const mode in state.display) {
        if (state.display[mode])
          ret.push(mode);
      }
      return ret;
    },

    getPoints: state => {
      return state.points
    },

    getIsLoading: state => {
      return state.isLoading
    },

    getCurrentPoint: state =>  {
      return state.currentPoint;
    },

    getCurrentMarker: state => {
      return state.currentMarker;
    },

    isAllPublicTransportationEnabled: state => {
      return publicTransportation.every((el) => {
        return state.display[el];
      })
    },
    isAllAlternativeTransportationEnabled: state => {
      return alternativeTransportation.every((el) => {
        return state.display[el];
      })
    },
    isAllPointOfInterestEnabled: state => {
      return pointOfInterest.every((el) => {
        return state.display[el];
      })
    },

    getPublicTransportations: state => {
      return publicTransportation;
    }
  },

  mutations: {
    enableAll: function(state) {

      Object.keys(state.display).forEach((el) => {
        state.display[el] = true;
      });
    },

    disableAll: function(state) {
      Object.keys(state.display).forEach((el) => {
        state.display[el] = false;
      });
    },

    enableModes: function(state, modes) {
      modes.forEach((el) => {
        state.display[el] = true;
      })
    },

    disableModes: function(state, modes) {
      modes.forEach((el) => {
        state.display[el] = false;
      })
    },

    onlyMode: function(state, mode) {
      if (mode) {

        let modeLC = mode.toLowerCase();

        for (let mode_ of ALL_MODES) {
          if (mode_.matching && mode_.matching.includes(modeLC)) {
            modeLC = mode_.name.toLowerCase();
            break;
          }
        }

        Object.keys(state.display).forEach((el) => {
          state.display[el] = (modeLC.toLowerCase() === el.toLowerCase());
        });
      }
    },

    toggleMode: function(state, {mode, value}) {
      state.display[mode] = value;
    },

    setPoints: function (state, points) {
      state.points = points.filter((el) => el && !!el.geometry);
    },

    addPoints: function (state, points) {

      const a = points.filter(
        ({ properties: {lat: lat1, lng: lng1, physical_mode: pm1}}) => !state.points.some(({ properties: {lat: lat2, lng: lng2, physical_mode: pm2}}) => lat1 === lat2 && pm1 === pm2 && lng1 === lng2));
      state.points.push(...a);
    },

    deleteModeFromPoints: function(state, mode)  {
      state.points = state.points.filter(el => el.properties.physical_mode !== mode);
    },

    setIsLoading: function(state, val) {
      state.isLoading = val;
    },

    setCurrentPoint: function(state, val) {
      state.currentPoint = val;
    },

    setCurrentMarker: function (state, {lng, lat}) {
      state.currentMarker = {lng: Number(lng).toFixed(6), lat: Number(lat).toFixed(6)};
    }
  },

  actions: {
    getTransportsAround ({commit, rootState}, payload) {

      commit("setIsLoading", true);

      HTTP
          .get(`/map/viewer/modes?coords=${rootState.coords.lat},${rootState.coords.lng}`, {
        params: {
          modes: payload.modes
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, {arrayFormat: "brackets"})
        },
        headers: payload.jwt ? {Authorization: "Bearer " + payload.jwt} : {}
      })
          .then((res) => {
            //*if (payload.keepOldData)
              commit("addPoints", res.data);
            //else
              //commit("setPoints", res.data);
          })
          .catch(err => {
            commit("alert/showError", payload.messages.ERROR_MODE_NOT_LOADED);
          })
          .finally(() => {
            commit("setIsLoading", false);
          });
    },

    getSettings({commit, rootState}, payload) {
      if (payload.jwt) {
        HTTP
          .get(`/map/users/${payload.userID}/settings`,
            {
              headers: payload.jwt ? {Authorization: "Bearer " + payload.jwt} : {}
            })
          .then((res) => {
            if (res.data.modes) {
              Object.keys(rootState.modes.display).forEach((el) => {

                if (res.data.modes.hasOwnProperty(el) && rootState.modes.display[el] !== res.data.modes[el]) {
                  commit("toggleMode", {mode: el, value: res.data.modes[el]})
                }
              });
            }
          })
      }
    },

    updateSettings ({commit, rootState}, payload) {
      if (payload.jwt) {
      HTTP
        .put(`/map/users/${payload.userID}/settings`, {
            modes: rootState.modes.display
          },
          {
          headers: payload.jwt ? {Authorization: "Bearer " + payload.jwt} : {}
        });

      }
    },

    switchMainTab({commit, rootState}, payload) {

      const transports = ["TRAIN", "METRO", "RER", "TRAMWAY", "SHUTTLE", "BUS", "BOAT", "CAR", "MOTORCYCLE", "ELECTRIC_SCOOTER", "BIKE", "BIKE_STATION"];
      const pois = ["DEFIBRILLATOR", "E_CHARGER", "WC"];
      //commit("")
      if (payload === "TRANSPORTS") {
        commit("enableModes", transports);
        commit("disableModes", pois);
        pois.forEach((el) => commit("deleteModeFromPoints", el));
      }
      else {
        commit("disableModes", transports);
        commit("enableModes", pois);
        transports.forEach((el) => commit("deleteModeFromPoints", el));
      }
    },

    togglePublicTransportation({commit, rootState}, payload) {

      if (payload) {
        commit("enableModes", publicTransportation);
      }
      else {
        commit("disableModes", publicTransportation);
        publicTransportation.forEach((el) => commit("deleteModeFromPoints", el));
      }
    },

    toggleAlternativeTransportation({commit, rootState}, payload) {

      if (payload) {
        commit("enableModes", alternativeTransportation);
      }
      else {
        commit("disableModes", alternativeTransportation);
        alternativeTransportation.forEach((el) => commit("deleteModeFromPoints", el));
      }
    },

    togglePointOfInterest({commit, rootState}, payload) {

      if (payload) {
        commit("enableModes", pointOfInterest);
      }
      else {
        commit("disableModes", pointOfInterest);
        pointOfInterest.forEach((el) => commit("deleteModeFromPoints", el));
      }
    },
  }
}

export default modes;
