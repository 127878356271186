import i18n from '../plugins/i18n'

function initialState()
{
  return {
    show: false,
    color: "",
    timeout: 6000,
    mode: "",
    text: ""
  }
}

const toast =
{
  namespaced: true,

  state: initialState(),

  getters:
  {
    isVisible: state =>
    {
      return state.show;
    },
    getColor: state =>
    {
      return state.color;
    },
    getTimeout : state =>
    {
      return state.timeout;
    },
    getMode: state =>
    {
      return state.mode;
    },
    getText: state =>
    {
      return state.text;
    }
  },

  mutations:
  {
    showSuccess: (state, payload) =>
    {
      state.color = "success";
      state.text = payload;
      state.show = true;
    },

    showError: (state, payload) =>
    {
      state.color = "error";
      state.text = payload;
      state.show = true;
    },

    showIndication: (state, payload) =>
    {
      state.color = "primary";
      state.text = payload;
      state.show = true;
    },

    showErrorAxios: (state, payload) =>
    {
      let text = null;

      if (payload && payload.response && payload.response.status && i18n.t('common.errors').hasOwnProperty(payload.response.status))
        text = i18n.t(`common.errors.${payload.response.status}`);
      else
        text = i18n.t(`common.errors.500`);

      state.color = "error";
      state.text = text;
      state.show = true;
    },


    setVisible: (state, payload) =>
    {
      state.show = payload;
    },

    hideToast: (state) =>
    {
      state.show = false;
    },

    reset (state)
    {
      const s = initialState();
      Object.keys(s).forEach(key =>
      {
        state[key] = s[key]
      })
    }
  }
};

export default toast;